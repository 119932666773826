<template>
  <div class="login">
    <div class="login-left">
      <div class="login-left-inner">
        <div class="login-left-inner-logo">
          <img src="/img/e-change-logo.svg" alt="logo" />
        </div>
        <div class="login-left-inner-text">
          <h1>Connexion</h1>
          <p>Veuillez vous connecter pour continuer avec votre compte</p>
        </div>
        <div v-if="!is_2fa_enabled" class="login-left-inner-form">
          <form role="form" @submit.prevent="handleSubmit()">
            <div v-if="error">
              <base-alert dismissible type="danger" icon="fa fa-exclamation">
                {{ error }}
              </base-alert>
            </div>

            <div class="form-group">
              <label for="email">Email</label>
              <div class="input-wrapper">
                <input
                  name="Email"
                  type="email"
                  label="Email"
                  :placeholder="$t('COMMON.EMAIL')"
                  v-model="email"
                />
                <validation-error :errors="apiValidationErrors.email" />
              </div>
            </div>

            <div class="form-group">
              <label for="password">Mot de passe</label>
              <div class="input-wrapper">
                <input
                  name="Password"
                  :placeholder="$t('COMMON.PASSWORD')"
                  v-model="password"
                  :type="fieldType"
                />
                <button
                  type="button"
                  @click="switchField"
                  class="password-switch"
                >
                  <i
                    class="fal"
                    :class="{
                      'fa-eye': fieldIconChange,
                      'fa-eye-slash': !fieldIconChange,
                    }"
                  ></i>
                </button>
                <validation-error :errors="apiValidationErrors.password" />
              </div>
            </div>

            <div class="stay-connected">
              <base-checkbox v-model="connected">
                <span>
                  <i class="far fa-check"></i>
                </span>
                Rester connecté
              </base-checkbox>
            </div>

            <div class="submit-wrapper">
              <base-button type="primary" native-type="submit">
                {{ $t("COMMON.SIGN_IN") }}
              </base-button>
            </div>
          </form>
          <div class="forgot-password">
            <a href="/password/reset" class="text-light">
              <small>{{ $t("COMMON.FORGOT_PASSWORD") }}</small>
            </a>
          </div>
        </div>

        <div v-if="is_2fa_enabled" class="login-left-inner-form">
          <div class="col-lg-5 col-md-7">
            <div class="card bg-secondary border-0 mb-0">
              <div class="card-body px-lg-5 py-lg-5">
                <div class="text-center my-5" style="margin-bottom: 5px">
                  <h1 class="">{{ appConfig.config_manager_app_name }}</h1>
                </div>
                <form
                  role="form"
                  @submit.prevent="handleSubmitCodeVerification()"
                >
                  <base-input
                    alternative
                    class="mb-3"
                    name="Password"
                    prepend-icon="fas fa-key"
                    type="text"
                    :placeholder="$t('COMMON.VERIFICATION_CODE')"
                    v-model="code"
                  >
                  </base-input>
                  <validation-error :errors="apiValidationErrors.code" />

                  <div v-if="error">
                    <base-alert
                      dismissible
                      type="danger"
                      icon="fa fa-exclamation"
                    >
                      {{ error }}
                    </base-alert>
                  </div>
                  <div class="row mt-1">
                    <div class="col-6">
                      <span
                        @click="handleSubmit"
                        style="cursor: pointer"
                        class="text-gray-dark"
                      >
                        <small>{{ $t("COMMON.RESEND_CODE") }}</small>
                      </span>
                    </div>
                  </div>

                  <div class="text-center">
                    <base-button
                      type="primary"
                      native-type="submit"
                      class="my-4"
                    >
                      {{ $t("COMMON.SIGN_IN") }}
                    </base-button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="login-right">
      <img src="/img/echange-login.png" alt="login" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";

export default {
  components: {
    ValidationError,
  },
  mixins: [formMixin],
  data: () => ({
    error: null,
    code: null,
    is_2fa_enabled: false,
    email: "",
    password: "",
    fieldType: "password",
    fieldIconChange: false,
  }),
  computed: {
    isAuthenticated: function () {
      return this.$store.getters.isAuthenticated();
    },
    ...mapGetters({
      appConfig: "appConfig/appConfig",
    }),
  },
  methods: {
    async handleSubmit() {
      this.error = null;
      const user = {
        data: {
          type: "token",
          attributes: {
            email: this.email,
            password: this.password,
          },
        },
      };

      const requestOptions = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
          Locale: this.$i18n.locale,
        },
      };

      try {
        const response = await this.$store.dispatch("login", {
          user,
          requestOptions,
        });
        if (!!response?.success) {
          this.is_2fa_enabled = !!response?.success;
          this.$notify({
            type: "info",
            //position: 'bottom-right',
            message: response?.message,
          });
        }
      } catch (error) {
        this.error = error?.response?.data?.errors?.[0].detail;
        this.$notify({
          type: "danger",
          message: error?.response?.data?.errors?.[0].detail,
        });
        error?.response?.data?.errors
          ? this.setApiValidation(error?.response?.data?.errors)
          : null;
      }
    },

    async handleSubmitCodeVerification() {
      this.error = null;
      const data = {
        data: {
          type: "users",
          attributes: {
            code: this.code,
          },
        },
      };

      try {
        await this.$store.dispatch("users/code_verification", data);
        this.$notify({
          type: "success",
          message: this.$t("COMMON.SUCCESS_VERIFICATION"),
        });
      } catch (error) {
        this.error = error?.response?.data?.errors?.[0]?.detail;
        this.$notify({
          type: "danger",
          message: this.$t("COMMON.ERROR_VERIFICATION"),
        });
        error?.response?.data?.errors
          ? this.setApiValidation(error?.response?.data?.errors)
          : null;
      }
    },

    switchField() {
      this.fieldType = this.fieldType === "password" ? "text" : "password";
      this.fieldIconChange = !this.fieldIconChange;
    },
  },
};
</script>
